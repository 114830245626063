<template>
  <v-card
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="45rem"
  >
    <v-avatar
      class="mt-10 mb-5"
      size="80"
      style="border-radius: 50%"
      :style="
        isContentFilledInCorrectly ? 'border: 4px solid #74EEB4' : 'border: 4px solid #D83232'
      "
    >
      <v-icon :color="isContentFilledInCorrectly ? '#74EEB4' : '#D83232'" x-large style="zoom: 1.3">
        {{ isContentFilledInCorrectly ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
    </v-avatar>

    <v-card-text class="px-8 px-md-15">
      <h1 class="headline mb-2 px-md-16 font-weight-black secondary--text">
        {{ isContentFilledInCorrectly ? $t('aml.summary') : $t('aml.summary.with_errors') }}
      </h1>
    </v-card-text>

    <v-card-text v-if="!isWithoutPoliticalVerification" class="py-0 px-5 px-md-15">
      <v-card class="authentication-summary__bordered" :color="workspaceLayerColor" elevation="0">
        <v-card-title class="px-0 pb-0 secondary--text">
          {{ $t('aml.questionnaire') }}
          <v-spacer></v-spacer>
          <v-icon @click="goToRoute('authentication-questionnaire')"> mdi-pencil-outline </v-icon>
        </v-card-title>

        <StatePreview :type="questionnaireFill.type" :label="questionnaireFill.message" />
      </v-card>
    </v-card-text>

    <v-card-text v-if="!isWithoutDocumentVerification" class="py-0 px-5 px-md-15">
      <v-card class="authentication-summary__bordered" :color="workspaceLayerColor" elevation="0">
        <v-card-title class="px-0 pb-0 secondary--text">
          {{ $t('aml.identity') }}
          <v-spacer></v-spacer>
          <v-icon @click="goToRoute('authentication-identity')"> mdi-pencil-outline </v-icon>
        </v-card-title>

        <StatePreview :type="documentFill.type" :label="documentFill.message" />

        <div class="pb-5 text-left">
          <v-avatar
            v-for="img in authsDocumentImages"
            :key="img.id"
            :size="$vuetify.breakpoint.mobile ? 70 : 90"
            class="mt-3 mr-3"
            rounded
            style="background-color: #e8edf5"
          >
            <v-img
              :src="img.file_url"
              width="100%"
              height="100%"
              style="object-fit: cover; border-radius: 8px; overflow: hidden"
            ></v-img>
          </v-avatar>
        </div>
      </v-card>
    </v-card-text>

    <v-card-text v-if="!isWithoutMicroTransactionVerification" class="py-0 px-5 px-md-15">
      <v-card :color="workspaceLayerColor" elevation="0">
        <v-card-title class="px-0 pb-0 secondary--text">
          {{ $t('aml.microtransaction') }}
          <v-spacer></v-spacer>
          <v-icon @click="goToRoute('authentication-micropayment')"> mdi-pencil-outline </v-icon>
        </v-card-title>

        <StatePreview :type="microTransactionFill.type" :label="microTransactionFill.message" />
      </v-card>
    </v-card-text>

    <v-card-text v-if="!isWithoutSmsVerification" class="py-0 px-5 px-md-15">
      <v-card class="authentication-summary__bordered" :color="workspaceLayerColor" elevation="0">
        <v-card-title class="px-0 pb-0 secondary--text">
          {{ $t('verification.sms') }}
          <v-spacer></v-spacer>
          <v-icon @click="goToRoute('authentication-sms')"> mdi-pencil-outline </v-icon>
        </v-card-title>

        <StatePreview :type="smsFill.type" :label="smsFill.message" />
      </v-card>
    </v-card-text>

    <v-card-actions class="mt-5 mb-15 px-10">
      <v-btn
        class="mt-5 mx-auto"
        color="primary"
        x-large
        min-width="15rem"
        :loading="isSubmitted"
        :disabled="!isContentFilledInCorrectly"
        @click="submit()"
      >
        {{ $t('aml.summary.send') }}
      </v-btn>
    </v-card-actions>

    <AlertMessage v-model="error" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StatePreview from '@verification/components/StatePreview';
import AlertMessage from '@verification/components/AlertMessage';

export default {
  name: 'Summary',
  components: {
    StatePreview,
    AlertMessage,
  },
  data() {
    return {
      error: false,
      submitted: false,
    };
  },
  computed: {
    ...mapGetters({
      isWithoutDocumentVerification: 'authsDocument/isWithoutDocumentVerification',
      isWithoutPoliticalVerification: 'authsPolitical/isWithoutPoliticalVerification',
      isWithoutMicroTransactionVerification:
        'authsMicroTransaction/isWithoutMicroTransactionVerification',
      isWithoutSmsVerification: 'authsSms/isWithoutSmsVerification',
      documentImages: 'authsDocument/documentImages',
      isPoliticalDone: 'authsPolitical/isPoliticalDone',
      isDocumentDone: 'authsDocument/isDocumentDone',
      isMicroTransactionDone: 'authsMicroTransaction/isMicroTransactionDone',
      isSmsDone: 'authsSms/isSmsDone',
      workspaceLayerColor: 'auths/workspaceLayerColor',
    }),
    authsStates() {
      return [
        {
          type: 'political',
          isDone: this.questionnaireFill || this.isWithoutPoliticalVerification,
        },
        {
          type: 'sms',
          isDone: this.smsFill || this.isWithoutSmsVerification,
          routeName: 'authentication-sms',
        },
        {
          type: 'document',
          isDone: this.documentFill || this.isWithoutDocumentVerification,
          routeName: 'authentication-identity',
        },
        {
          type: 'micropayment',
          isDone: this.microTransactionFill || this.isWithoutMicroTransactionVerification,
          routeName: 'authentication-micropayment',
        },
      ];
    },
    authsDocumentImages() {
      return this.documentImages;
    },
    questionnaireFill() {
      return {
        type: this.getCurrentType(this.isPoliticalDone),
        message: this.isPoliticalDone ? this.$t('general.filled2') : this.$t('general.blank'),
      };
    },
    documentFill() {
      return {
        type: this.getCurrentType(this.isDocumentDone),
        message: this.isDocumentDone ? this.$t('general.filled2') : this.$t('general.blank'),
      };
    },
    microTransactionFill() {
      return {
        type: this.getCurrentType(this.isMicroTransactionDone),
        message: this.$t('aml.micro_payment.statement.state_message'),
      };
    },
    smsFill() {
      return {
        type: this.getCurrentType(this.isSmsDone),
        message: this.isSmsDone ? this.$t('general.verified') : this.$t('general.unverified'),
      };
    },
    isContentFilledInCorrectly() {
      return this.authsStates?.every((item) => item.isDone);
    },
    isSubmitted() {
      return this.submitted;
    },
  },
  methods: {
    ...mapActions({
      setModeEditing: 'auths/setModeEditing',
      setModeBackgroundUpdates: 'auths/setModeBackgroundUpdates',
      submitDocument: 'authsDocument/submitDocument',
      submitProcess: 'auths/submitProcess',
    }),
    getCurrentType(correct) {
      return correct ? 'success' : 'error';
    },
    goToRoute(name) {
      const authToken = this.$route.params.authToken;
      this.setModeEditing(true);

      return this.$router.push({ name, params: { authToken } });
    },
    async submit() {
      this.submitted = true;
      this.setModeBackgroundUpdates(false);

      if (this.isWithoutDocumentVerification) {
        await this.submitDocument({
          authToken: this.$route.params.authToken,
        });
      }

      this.submitProcess(this.$route.params.authToken)
        .then(() => {
          this.$router.push({
            name: 'authentication-done',
            params: {
              authToken: this.$route.params.authToken,
            },
          });
        })
        .catch(() => {
          this.submitted = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.authentication-summary {
  &__bordered {
    border-bottom: 1px solid #dee7f4;
  }
}
</style>
